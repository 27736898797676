import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
/**
 * 解决重复点击路由报错问题
 */

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};
const VueRouterReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(to) {
  return VueRouterReplace.call(this, to).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "main",
    component: () => import("@/pages/main"),
  },
  {
    path: "/statsmain",
    name: "Home",
    component: () => import("@/pages/Home"),
  },
  {
    path: "/noPass",
    name: "noPass",
    component: () => import("@/pages/noPass"),
  },
  // 单位文章统计
  {
    path: "/contentStats",
    name: "contentStats",
    component: () => import("@/pages/contentStats/contentStatsNew"),
  },

  // 个人文章阅读统计/已读未读
  {
    path: "/userContentStats",
    name: "userContentStats",
    component: () => import("@/pages/contentStats/userContentStats"),
  },
  // 文章切片分析
  {
    path: "/contentSlice",
    name: "contentSlice",
    component: () => import("@/pages/contentStats/contentSlice"),
  },

  // 五率
  {
    path: "/deptFiveStatus",
    name: "deptFiveStatus",
    component: () => import("@/pages/deptFiveStatusNew"),
  },
  // 运动健康--个人
  {
    path: "/personalRun",
    name: "personalRun",
    component: () => import("@/pages/run/personalRun"),
  },
  {
    path: "/deptRun",
    name: "deptRun",
    component: () => import("@/pages/run/deptRunNew"),
  },

  // 部门用户关注
  {
    path: "/deptUserFollow",
    name: "deptUserFollow",
    component: () => import("@/pages/deptUser/deptUserFollow"),
  },
  // {
  //   path: "/deptUserFollowOld",
  //   name: "deptUserFollowOld",
  //   component: () => import("@/pages/deptUser/deptUserFollowOld"),
  // },
  // 部门完整率
  {
    path: "/deptUserData",
    name: "deptUserData",
    component: () => import("@/pages/deptUser/deptUserData"),
  },

  {
    // 添加用户
    path: "/addUser",
    name: "addUser",
    component: () => import("@/pages/userMgt/addUser"),
  },

  {
    // 修改手机号
    path: "/editMobile",
    name: "editMobile",
    component: () => import("@/pages/userMgt/editMobile"),
  },

  {
    // 修改部门
    path: "/changeDept",
    name: "changeDept",
    component: () => import("@/pages/userMgt/changeDept"),
  },

  {
    // 单位积分
    path: "/deptJifen",
    name: "deptJifen",
    component: () => import("@/pages/jifen/deptJifenNew"),
  },
  {
    // 单位积分
    path: "/personJifen",
    name: "personJifen",
    component: () => import("@/pages/jifen/personJifen"),
  },

  {
    // 积分排行
    path: "/jifenRank",
    name: "jifenRank",
    component: () => import("@/pages/jifen/jifenRank"),
  },

  {
    // 用户活跃度
    path: "/personDegree",
    name: "personDegree",
    component: () => import("@/pages/activeDegree/personDegree"),
  },
  {
    // 单位活跃度
    path: "/deptDegree",
    name: "deptDegree",
    component: () => import("@/pages/activeDegree/deptDegreeNew"),
  },

  {
    // 活跃度排行帮
    path: "/degreeRank",
    name: "degreeRank",
    component: () => import("@/pages/activeDegree/degreeRank"),
  },
  {
    // 活跃度排行帮
    path: "/userDegree",
    name: "userDegree",
    component: () => import("@/pages/activeDegree/userDegreeNew"),
  },

  {
    // 文章统计
    path: "/contentRank",
    name: "contentRank",
    component: () => import("@/pages/deptContent/contentRank"),
  },
  {
    // 文章统计
    path: "/deptContent",
    name: "deptContent",
    component: () => import("@/pages/deptContent/deptContentNew"),
  },

  {
    // 文章统计
    path: "/deptContentNew",
    name: "deptContentNew",
    component: () => import("@/pages/deptContent/deptContentNew"),
  },

  //
  {
    // 阅读行为
    path: "/userRead",
    name: "userRead",
    component: () => import("@/pages/readingBehavior/userRead"),
  },
  {
    // 单位阅读行为
    path: "/deptRead",
    name: "deptRead",
    component: () => import("@/pages/readingBehavior/deptReadNew"),
  },

  {
    // 擂台排行
    path: "/leitaiRank",
    name: "leitaiRank",
    component: () => import("@/pages/leitai/leitaiRank"),
  },
  {
    // 擂台排行
    path: "/deptLeitai",
    name: "deptLeitai",
    component: () => import("@/pages/leitai/deptLeitai"),
  },
  {
    // 个人擂台
    path: "/personLeiTai",
    name: "personLeiTai",
    component: () => import("@/pages/leitai/personLeiTai"),
  },

  {
    // 用户阅读报表
    path: "/userReadStats",
    name: "userReadStats",
    component: () => import("@/pages/userReadStats"),
  },

  {
    // 微利指数
    path: "/deptWeili",
    name: "deptWeili",
    component: () => import("@/pages/weili/deptWeili"),
  },

  {
    // 职工标签-单位阅读统计分析
    path: "/userLabelDeptStats",
    name: "userLabelDeptStats",
    component: () => import("@/pages/label/userLabelDeptStats"),
  },
  {
    // 职工标签-个人阅读统计分析
    path: "/userLabelUserStats",
    name: "userLabelUserStats",
    component: () => import("@/pages/label/userLabelUserStats"),
  },
  {
    // 职工标签-文章阅读统计分析
    path: "/userLabelContentStats",
    name: "userLabelContentStats",
    component: () => import("@/pages/label/userLabelContentStats"),
  },

  {
    // 文章标签-个人阅读统计分析
    path: "/contentLabelUserStats",
    name: "contentLabelUserStats",
    component: () => import("@/pages/label/contentLabelUserStats"),
  },
  {
    // 文章标签-单位阅读统计分析
    path: "/contentLabelDeptStats",
    name: "contentLabelDeptStats",
    component: () => import("@/pages/label/contentLabelDeptStats"),
  },
  {
    // 关键字-个人阅读统计分析
    path: "/keywordUserStats",
    name: "keywordUserStats",
    component: () => import("@/pages/label/keywordUserStats"),
  },
  {
    // 关键字-单位阅读统计分析
    path: "/keywordDeptStats",
    name: "keywordDeptStats",
    component: () => import("@/pages/label/keywordDeptStats"),
  },
  {
    // 热点分析-单位阅读统计分析
    path: "/hotLabelDeptStats",
    name: "hotLabelDeptStats",
    component: () => import("@/pages/label/hotLabelDeptStats"),
  },

  {
    // 情感分析-舆情报告
    path: "/sentimentReport",
    name: "sentimentReport",
    component: () => import("@/pages/reportMsg/sentimentReport"),
  },

  {
    // 阅读报告-个人报告
    path: "/userReport",
    name: "userReport",
    component: () => import("@/pages/reportMsg/userReport"),
  },
  {
    // 阅读报告-个人报告
    path: "/perReport",
    name: "perReport",
    component: () => import("@/pages/reportMsg/perReport"),
  },
  // {
  //   // 阅读报告-个人报告翻页
  //   path: "/perReportSw",
  //   name: "perReportSw",
  //   component: () => import("@/pages/reportMsg/perReportSwiper"),
  // },
  {
    // 阅读报告-单位报告
    path: "/deptReport",
    name: "deptReport",
    component: () => import("@/pages/reportMsg/deptReport"),
  },
  {
    // 阅读报告-部门报告
    path: "/secReport",
    name: "secReport",
    component: () => import("@/pages/reportMsg/secReport"),
  },
  {
    // 阅读报告-部门报告翻页
    path: "/secReportSw",
    name: "secReportSw",
    component: () => import("@/pages/reportMsg/secReportSwiper"),
  },
  {
    // 阅读报告-集团报告
    path: "/jtReport",
    name: "jtReport",
    component: () => import("@/pages/reportMsg/jtReport"),
  },

  // 投稿管理
  {
    path: "/tougaoEditor",
    name: "tougaoEditor",
    component: () => import("@/pages/tougao/tougaoEditor"),
  },
  {
    path: "/tougaoList",
    name: "tougaoList",
    component: () => import("@/pages/tougao/tougaoList"),
  },
  {
    path: "/tougaoReview",
    name: "tougaoReview",
    component: () => import("@/pages/tougao/tougaoReview"),
  },
  {
    path: "/contentReview",
    name: "contentReview",
    component: () => import("@/pages/examine/contentReview"),
  },
  {
    path: "/messageReview",
    name: "messageReview",
    component: () => import("@/pages/examine/messageReview"),
  },
  {
    path: "/zdMessageReview",
    name: "zdMessageReview",
    component: () => import("@/pages/examine/zdMessageReview"),
  },
  {
    path: "/messageDetail",
    name: "messageDetail",
    component: () => import("@/pages/examine/messageDetail"),
  },
  {
    path: "/zdReview",
    name: "zdReview",
    component: () => import("@/pages/examine/zdReview"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
