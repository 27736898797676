const directive = {
  inserted(el,binding) {
    let count = 0 //初始值
    let num = el.innerText //获取页面中的值
    let rate = 30 //定时器间隔时间
    let average = num / (binding.value / rate) //得到每次定时器跳动的值
  
    const timer = setInterval(() => {
          // 将我们得到的值设置给count
          count += average
          el.innerText = count.toFixed(0)

          if(count > num){
            //为了防止数字错误将初始的值给他
            count = num
            el.innerText = count
            //清除定时器
            clearInterval(timer)
          }
    }, rate);

  },
};
export default directive