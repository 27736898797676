<template>
  <div id="app">
    <div id="nav">
      <!-- <user-data></user-data> -->
      <wx-login-cmpt v-if="isLogin"></wx-login-cmpt>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import wxLoginCmpt from "./components/wxLoginCmpt.vue";

export default {
  name: "App",
  data() {
    return {
      isLogin: true,
    };
  },
  async created() {},
  methods: {},

  mounted: function () {
    require("./css/common.css");
  },
  components: {
    wxLoginCmpt,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  margin: 0;
  padding: 0;
}
/** 公共样式 */
.buttonLarge {
  appearance: none;
  border-radius: 4px;
  height: 41px;
  line-height: 41px;
  font-size: 18px;
  outline: none;
  text-align: center;
  /* width: %; */
  color: #5b7498;
  margin: 0 auto;
  border: 1px solid #5b7498;
}
.buttonLarge::after {
  background-color: black;
  content: " ";
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: 0;
}

.main {
  width: 100vw;
  height: 100vh;
  background: var(--mainBg);
  /* overflow: hidden; */

  overflow-y: scroll;
}

.noscrollmain {
  width: 100vw;
  height: 100vh;
  background: var(--mainBg);
  overflow: hidden;
}

.scrollDiv {
  overflow-y: scroll;
  height: 90vh;
}
</style>
