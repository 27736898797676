<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<template>
  <div>
    <!-- 隐藏组件，登录成功后查询用户其他数据，写入store -->
  </div>
</template>

<script>
import debug from "debug";

import _ from "lodash";
import wxLoginApi from "../api/wxLoginApi";
import {
  userPmPost,
  statsPost,
  setLocalStorage,
  getUserPm,
} from "../api/httpApi"; // Data request API
const _d = debug("@cmpt:userData");
export default {
  name: "userData",
  data() {
    return {
      userData: {},
      userPmData: {},
      level: 0,
      zdDeptIdArr: [],
      exp: 10 * 1000, // 增加过期时间
    };
  },
  methods: {
    // 查询用户 站段栏目deptIdArr、pmValue
    async getUserData() {
      // 任何情况下，用户权限均重新获取最新的，storage 中的权限紧做异常使用
      try {
        const result = await userPmPost("/services/gttt-user-mgt/pm/userPm", {
          onlyPm: true,
        });
        localStorage.token = result.newToken; // 重写token
        // 数据写入storage中
        setLocalStorage("statsUserData", result.userPm);
        this.userPmData = result.userPm;

        // 通知数据获取成功
        this.$emit("userData", result);
      } catch (e) {
        _d("xxxxxxxxxxx获取用户其他数据Err", e);
      }
    },
    // 根据用户level 查询默认可管理部门
    async getDept() {
      try {
        let parentDept = [];
        // 超管有全部权限
        console.log("------", this.userPmData);
        // 之前权限有数组和字符串类型，统一合并
        if (
          this.userPmData.pmIndex &&
          !Array.isArray(this.userPmData.pmIndex)
        ) {
          this.userPmData.pmIndex = [this.userPmData.pmIndex];
        }
        if (
          this.userPmData.pmIndex.indexOf("990") > -1 ||
          this.userPmData.pmIndex.indexOf("999") > -1 ||
          this.userPmData.pmIndex.indexOf("950") > -1 // 统计超管
        ) {
          this.level = 2;
          parentDept = [1];
        } else {
          this.level = this.userPmData.level;
          parentDept =
            (this.userPmData.pmValue && this.userPmData.pmValue.zdStats) ||
            (this.userPmData.pmValue && this.userPmData.pmValue.txl) ||
            [];
        }

        const result = await statsPost("/dept/getDept", {
          level: this.level,
          parentDept,
        });
        const deptIdArr = _.map(result.ret, "deptId");
        localStorage.setItem("pmDeptIdArr", JSON.stringify(deptIdArr));
      } catch (e) {
        console.log("--------", e);
      }
    },
    // 截取字符串
    getQueryString(name) {
      const url = window.location.href;
      const r = url.match(
        // eslint-disable-next-line
        new RegExp(`([\?|&])${name}=([^|&||#|\/]*)(&|#|\/|$)`)
      );
      if (r !== null) {
        const str = decodeURI(r[2]);
        const pos = str.indexOf("#/");
        if (pos >= 0) {
          return str.substr(0, pos);
        }
        debug("str====", str);
        return str;
      }
      return null;
    },
  },
  created() {
    // 登录成功，查询用户信息
    wxLoginApi.onReady(async () => {
      this.userData = wxLoginApi.getUser();
      // // userReport
      // const path = getQueryString("path");
      // if(['userReport'].indexOf(path) > -1) {

      // }
      await this.getUserData();
      await this.getDept();
    });
  },
};
</script>
