/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";

/******************* 预加载类型*********************************/

Vue.use(Vuex);

const state = {
  user: {}, // 用户信息
  userData: {}, // 用户其他信息
};

export default new Vuex.Store({
  state,
});
