<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<template>
  <div>
    <!-- 微信初始化用户信息组件 -->
    <div v-show="loginStatus">
      <!--
        获取用户信息(站段部门)组件 站段栏目deptIdArr、我的消息：部门、系统、职名、职务
      -->
      <!-- <user-data></user-data> -->
    </div>
    <h1 v-if="showWarning" style="text-align: center">
      <!-- 登录接口故障，请联系技术人员 @18310343563 ！ -->
    </h1>
  </div>
</template>

<script>
import debug from "debug";
import userData from "./userData.vue";
import wxLoginApi from "../api/wxLoginApi";

const _d = debug("@wxLoginCmpt");

export default {
  name: "",
  data () {
    return {
      loginStatus: false,
      showWarning: false,
      timer: null,
    };
  },
  props: {
    login: {
      type: Boolean,
      default: true,
    },
  },
  components: { userData },
  methods: {
    getQueryString (name) {
      //   debug('name=======', name);
      const r = window.location.href.match(
        // eslint-disable-next-line
        new RegExp(`([\?|&])${name}=([^|&]*)(&|$)`)
      );
      if (r !== null) {
        const str = decodeURI(r[2]);
        const pos = str.indexOf("#/");
        if (pos >= 0) {
          return str.substr(0, pos);
        }
        return str;
      }
      return null;
    },
  },
  async created () {
    _d("START LOGIN CHECK");
    // 先恢复token，如有效，不需要登录
    try {
      wxLoginApi.init(false, this.$router); // 实际登录
      // wxLoginApi.init(true, this.$router); // 本地调试
      //
      wxLoginApi.onReady(async () => {
        _d("wxLoginApi onReady");
        console.log("----", this.getQueryString("path"));
        if (!this.getQueryString("path")) {
          console.log("22----", this.getQueryString("path"));
          this.$router.push({ path: "/statsmain" });
        }
        this.loginStatus = true; // 登录成功，显示页面
        this.$store.state.user = wxLoginApi.getUser();
        _d("this.$store.state.user", this.$store.state.user);
        // 签到cd to

        this.timer = setInterval(() => {
          _d("refreshToken");
          wxLoginApi.refreshToken();
        }, 3 * 60 * 1000);
      });
      setTimeout(() => {
        if (!this.loginStatus) {
          this.showWarning = true; // 接口不通，显示提示信息
        }
      }, 3000);
      _d("login first", this.$store.state.user);
    } catch (e) { }
  },
  destroyed () {
    if (this.timer) {
      _d("--------------------------------关闭token定时器", this.timer);
      clearInterval(this.timer);
    }
  },
};
</script>
