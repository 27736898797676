/* eslint-disable  */
/**
 *
 */
const debug = require("debug")("@config:httpApi");
const agentid = "1000038"; // 华为---  当前版本

const WXGW_SERVER = "https://ali.gtrmt.cn:8895"; // 微信网k关APIHTTPS协议
// // ------------------ HTTP 本地测试
//------------------ HTTPS 部署

// http://192.168.31.32:8080/?path=deptReport&deptId=1&e=1673107200000&s=1672588800000#/deptReport
// const content_SERVER = "http://localhost:9000"; // 发布平台数据 HTTPS协议 部署API
// const production_server = "http://localhost:9000"; // 生产平台接口

const content_SERVER = "https://mgt.gtrmt.cn"; // 发布平台数据 HTTPS协议 部署API
const production_server = "https://mgt.gtrmt.cn"; // 生产平台接口

// const stats_Server = "http://192.168.31.135:9000";
// const stats_Server = "http://192.168.32.246:9000";
const stats_Server = "https://tj.gtrmt.cn:8889";

const user_Href = "https://gt-wx.gtrmt.cn/"; // 华为测试

module.exports = {
  stats_Server() {
    return stats_Server;
  },
  WXGW_SERVER() {
    return WXGW_SERVER;
  },
  content_SERVER() {
    return content_SERVER;
  },
  production_server() {
    return production_server;
  },
  getAgentid() {
    return agentid;
  },
  getHref() {
    return user_Href;
  },
};
