import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "swiper/swiper.min.css"
import ViewUI from "view-design";
import directive from "@/components/change"
import "view-design/dist/styles/iview.css";

import "font-awesome/css/font-awesome.min.css";

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
Vue.prototype.gsap = gsap
Vue.prototype.ScrollTrigger = ScrollTrigger
Vue.config.productionTip = false;
Vue.directive("change", directive);
const store = require("./store").default;

Vue.use(ViewUI);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
